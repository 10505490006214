import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  Table,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  flexRender,
  SortingState,
  getPaginationRowModel,
  RowSelectionState,
  Row,
  Header,
} from '@tanstack/react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import Pagination from './Pagination';
import { StrictColumnDef } from './strictColumnDef';
import { Colors, MediaQuery, Sizes } from 'utils/style';
import React from 'react';
import { StrictSortingState } from './strictSortingState';

const StyledTable = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .divTable {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .tbody {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .thead {
    position: sticky;
    top: 0;
    min-width: max-content;
    width: 100%;
    background-color: ${Colors.White};

    .thead-group {
      display: flex;
      border-bottom: 2px solid ${Colors.Gray};
    }
  }

  .tr {
    display: flex;
    min-width: max-content;
    width: 100%;

    background: ${Colors.White};
    border-bottom: 1px solid ${Colors.Gray};

    &:hover {
      background-color: #ddd;
    }
  }

  .th,
  .td {
    padding: 5px 10px;

    &:first-child {
      padding-left: ${Sizes.Gutter / 2}px;
    }
    &:last-child {
      padding-right: ${Sizes.Gutter / 2}px;
    }

    ${MediaQuery.tablet} {
      &:first-child {
        padding-left: ${Sizes.Gutter}px;
      }
      &:last-child {
        padding-right: ${Sizes.Gutter}px;
      }
    }
  }

  .th {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    border-right: 1px solid transparent;
    user-select: none;
    font-weight: 600;
    white-space: nowrap;

    &::before {
      content: '';
      position: absolute;
      left: -2px;
      top: 50%;
      transform: translateY(-50%);
      height: 15px;
      width: 2px;
      border-radius: 10px;
      background: ${Colors.Gray};
    }
  }

  .th-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-right: 1px solid transparent;
  }

  .resizer {
    position: absolute;
    top: 0;
    right: -25px;
    z-index: 1;
    height: 100%;
    width: 50px; // intentionally wider to make it more touch friendly.
    cursor: col-resize;
    touch-action: none;

    ${MediaQuery.desktop} {
      width: 25px;
      right: -12px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      width: 4px;
      border-radius: 10px;
      transition: background-color 0.2s, box-shadow 0.2s;
    }

    &:hover::before,
    &.isResizing::before {
      background-color: ${Colors.Selected};
    }
  }

  /* Cheat to prevent the last resizer from overflowing the last column. (overflow creates a scrollbar) */
  .th:last-child .resizer {
    width: 3px;
    right: -1px;

    &:hover {
      box-shadow: 1px 0 0 0 ${Colors.Selected};
    }
  }
`;

interface Props<T> {
  data: T[];
  columns: StrictColumnDef<T, any>[];
  pagination?: boolean;
  initialRowSelection?: RowSelectionState;
  initialSortingState?: StrictSortingState<T>;
  getRowId?:
    | ((originalRow: T, index: number, parent?: Row<T> | undefined) => string)
    | undefined;
}

export const useTableSettings = <T,>(props: Props<T>) => {
  const {
    data,
    columns,
    pagination = false,
    initialRowSelection = {},
    initialSortingState = [],
    getRowId,
  } = props;

  const [sorting, setSorting] = useState<SortingState>(initialSortingState);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [rowSelection, setRowSelection] = useState(initialRowSelection);

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      sorting,
      rowSelection,
    },
    getRowId,
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,

    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,

    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: pagination ? getPaginationRowModel() : undefined,

    columnResizeMode: 'onChange',
    defaultColumn: { minSize: 60 },
  });

  return { table };
};

interface StatelessTableProps<T> {
  table: Table<T>;
  pagination?: boolean;
  onRowClick?: (data: T, row: Row<T>, table: Table<T>) => void;
}

export const StatelessTable = <T,>({
  table,
  pagination = false,
  onRowClick,
}: StatelessTableProps<T>) => {
  const useFixedColumnWidths = useRef(false);
  const tHeadRefByColumnId = useRef<Record<string, HTMLDivElement>>({});
  const divTableRef = useRef<HTMLDivElement>(null);

  /**
   * Mount the table with dynamic column widths (using flex), and then set the column widths to fixed values.
   * Fixed column widths are required to make column resizing work properly.
   */
  useEffect(() => {
    if (!useFixedColumnWidths.current) {
      useFixedColumnWidths.current = true;

      table.setColumnSizing((sizes) => {
        const newSizes = { ...sizes };

        table.getAllColumns().forEach((column) => {
          const ref = tHeadRefByColumnId.current[column.id];
          if (ref) {
            newSizes[column.id] = ref.offsetWidth;
          }
        });

        return newSizes;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const makeWidthStyle = (size: number): React.CSSProperties =>
    useFixedColumnWidths.current
      ? {
          minWidth: size,
          maxWidth: size,
        }
      : {
          flex: 1,
          minWidth: size,
        };

  const handleResizeStart = (
    e: React.PointerEvent<HTMLDivElement>,
    header: Header<T, unknown>
  ) => {
    e.preventDefault();
    e.stopPropagation();

    divTableRef.current?.style.setProperty('touch-action', 'none');

    const target = e.currentTarget;
    const startWidth = tHeadRefByColumnId.current[header.column.id].offsetWidth;
    const startX = e.clientX;

    const handleResizeMove = (e: PointerEvent) => {
      e.preventDefault();
      e.stopPropagation();

      const deltaX = e.clientX - startX;
      const newWidth = Math.max(0, startWidth + deltaX);

      table.setColumnSizing((sizes) => ({
        ...sizes,
        [header.column.id]: newWidth,
      }));
    };

    const pointerId = e.pointerId;
    const handlePointerUp = (e: PointerEvent) => {
      divTableRef.current?.style.removeProperty('touch-action');
      target.removeEventListener('pointermove', handleResizeMove);
      target.removeEventListener('pointerup', handlePointerUp);
    };

    target.setPointerCapture(pointerId);
    target.addEventListener('pointermove', handleResizeMove);
    window.addEventListener('pointerup', handlePointerUp); // Use window instead of target to prevent the event from being lost if the pointer leaves the target.
  };

  return (
    <StyledTable>
      <div className="divTable" ref={divTableRef}>
        <div className="thead">
          {table.getHeaderGroups().map((headerGroup) => (
            <div key={headerGroup.id} className="thead-group">
              {headerGroup.headers.map((header) => (
                <div
                  key={header.id}
                  className="th"
                  style={makeWidthStyle(header.column.getSize())}
                  onClick={
                    header.column.getCanSort()
                      ? header.column.getToggleSortingHandler()
                      : undefined
                  }
                  role="button"
                  tabIndex={0}
                  onKeyUp={(eve) => {
                    if (eve.key === 'Enter' && header.column.getCanSort()) {
                      header.column.toggleSorting();
                    }
                  }}
                  ref={(el) => {
                    if (el) {
                      tHeadRefByColumnId.current[header.column.id] = el;
                    }
                  }}
                >
                  <span className="th-text">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </span>
                  {
                    {
                      asc: <FontAwesomeIcon icon={faAngleUp} />,
                      desc: <FontAwesomeIcon icon={faAngleDown} />,
                      none: null,
                    }[header.column.getIsSorted() || 'none']
                  }
                  <div
                    aria-hidden
                    onClick={(e) => e.stopPropagation()}
                    onPointerDown={(e) => handleResizeStart(e, header)}
                    className={`resizer ${
                      header.column.getIsResizing() ? 'isResizing' : ''
                    }`}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>

        <div className="tbody">
          {table.getRowModel().rows.map((row) => (
            <div
              key={row.id}
              className="tr"
              role="button"
              tabIndex={0}
              onKeyUp={(eve) => {
                if (eve.key === 'Enter') {
                  if (onRowClick) {
                    onRowClick(row.original, row, table);
                  }
                }
              }}
              onClick={() => {
                if (onRowClick) {
                  onRowClick(row.original, row, table);
                }
              }}
            >
              {row.getVisibleCells().map((cell) => (
                <div
                  key={cell.id}
                  className="td"
                  style={makeWidthStyle(cell.column.getSize())}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>

      {pagination && table.getPaginationRowModel() && (
        <Pagination table={table} />
      )}
    </StyledTable>
  );
};

export interface ReactTableProps<T> extends Props<T> {
  onRowClick?: (data: T, row: Row<T>, table: Table<T>) => void;
}

const ReactTable = <T,>({ onRowClick, ...props }: ReactTableProps<T>) => {
  const { table } = useTableSettings(props);

  return (
    <StatelessTable
      table={table}
      pagination={props.pagination}
      onRowClick={onRowClick}
    />
  );
};

export default ReactTable;
