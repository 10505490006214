import React, {
  FunctionComponent,
  useRef,
  useCallback,
  useEffect,
} from 'react';
import styled, { css } from 'styled-components';
import { Colors } from 'utils/style';

export const tableCellClass = 'cell';
export const tableHeadClass = 'head';
export const firstColumnClass = 'fc';
export const lastColumnClass = 'lc';
export const firstRowClass = 'fr';
export const lastRowClass = 'lr';

export const rowClickerClass = 'row-clicker';

interface TableBodyProps {
  columnSettings: ColumnSetting<any, any>[];
  asClickable: boolean;
}
const TableBody = styled.div<TableBodyProps>`
  display: grid;
  overflow: auto;
  position: relative;

  .${tableHeadClass} {
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    padding: 7px 5px;

    background: ${Colors.White};
    border-right: 1px solid ${Colors.Gray};
    border-bottom: 2px solid ${Colors.Gray};
    font-weight: bold;
    cursor: default;
  }
  .${tableHeadClass}.${lastColumnClass} {
    border-right: 0;
  }

  .${tableCellClass} {
    display: flex;
    align-items: flex-end;
    padding: 7px 5px;

    white-space: pre-wrap;
    border-right: 1px solid ${Colors.Gray};
    border-bottom: 1px solid ${Colors.Gray};
  }

  .${tableCellClass}.${lastRowClass} {
    border-bottom: 0;
  }
  .${tableCellClass}.${lastColumnClass} {
    border-right: 0;
  }

  .${rowClickerClass} {
    position: absolute;
    width: 100%;
    background: #0000001a;
  }

  ${({ asClickable }) => asClickable && 'cursor: pointer;'}

  ${({ columnSettings }) =>
    columnSettings.map((column, index) =>
      column.cellStyle
        ? css`
            ${makeTableCellPositionSelector(
              undefined,
              String(column.columnIdentifier ?? index),
              column.skipStylingHeader ? true : false
            )} {
              ${column.cellStyle}
            }
          `
        : ''
    )}
`;

export const makeEverySecondRowSelector = (columnLength: number) =>
  new Array(columnLength)
    .fill('')
    .map(
      (_, i) =>
        `div.${tableCellClass}:nth-child(${columnLength * 2}n + ${
          columnLength * 2 - i
        })`
    )
    .join(',\n');

export const makeTableCellPositionSelector = (
  rowIdentifier?: string,
  columnIdentifier?: string,
  skipHeader?: boolean
) =>
  (!!rowIdentifier ? `.r-${rowIdentifier}` : '') +
  (!!columnIdentifier ? `.c-${columnIdentifier}` : '') +
  (skipHeader ? ':not(.head)' : '');

const makeTableCellPositionClass = (
  columnIndex: number,
  rowIndex: number,
  columnLength: number,
  rowLength: number,
  columnIdentifier: string,
  rowIdentifier: string
) =>
  (!!rowIdentifier ? `r-${rowIdentifier} ` : '') +
  (!!columnIdentifier ? `c-${columnIdentifier} ` : '') +
  (columnIndex === 0 ? `${firstColumnClass} ` : '') +
  (columnIndex === columnLength - 1 ? `${lastColumnClass} ` : '') +
  (rowIndex === 0 ? `${firstRowClass} ` : '') +
  (rowIndex === rowLength - 1 ? `${lastRowClass} ` : '');

export interface CellRendererProps<Obj> {
  rowIndex: number;
  row: Obj;
  rowIdentifier: string;
}

export interface ColumnSetting<Obj extends Object, Attr extends keyof Obj> {
  /** string that is used to identify a column as css class. Defaults to index.*/
  columnIdentifier?: string;
  header: React.ReactChild;
  attribute?: Attr;
  cellRenderer?: FunctionComponent<CellRendererProps<Obj>>;
  formatter?(value: Obj[Attr]): string;
  onHeaderClick?(): void;

  /** css value for column width (uses grid-template-columns internally). Defaults to '1fr'.*/
  width?: string;
  /** Create a custom style for the cells in this column */
  cellStyle?: ReturnType<typeof css>;
  /** Skip header when applying cellStyle */
  skipStylingHeader?: boolean;
}

export interface Props<Obj extends Object> {
  columnSettings: ColumnSetting<Obj, keyof Obj>[];
  /** returns a string that is used to identify a row using a css class, and internally as row key */
  rowIdentifier?(row: Obj): string;
  rows: Obj[];
  onRowClick?(rowIdentifier: string): void;
  className?: string;
}

/**
 * @deprecated use ReactTable component instead.
 */
const Table = <Row extends object>({
  columnSettings,
  rowIdentifier: rowIdentifierCreator,
  rows,
  className,
  onRowClick,
}: Props<Row>) => {
  const bodyRef = useRef<HTMLDivElement>();
  const rowClickRef = useRef<HTMLDivElement>(null);
  const hoveredRowClass = useRef('');
  const onRowClickRef = useRef(onRowClick);
  onRowClickRef.current = onRowClick;

  const handleHover = useCallback((eve: MouseEvent) => {
    if (
      eve.target instanceof HTMLElement &&
      eve.target.classList.contains(tableCellClass)
    ) {
      if (rowClickRef.current) {
        rowClickRef.current.style.top = `${eve.target.offsetTop}px`;
        rowClickRef.current.style.height = `${eve.target.offsetHeight}px`;
      }

      eve.target.classList.forEach((clss) => {
        if (/^r-\d+$/.test(clss)) {
          hoveredRowClass.current = clss;
        }
      });
    } else if (rowClickRef.current && eve.target !== rowClickRef.current) {
      rowClickRef.current.style.top = '';
      rowClickRef.current.style.height = '';
    }
  }, []);

  const handleLeave = useCallback((eve: MouseEvent) => {
    if (rowClickRef.current) {
      rowClickRef.current.style.top = '';
      rowClickRef.current.style.height = '';
    }
  }, []);

  const handleClick = useCallback((eve: MouseEvent) => {
    if (
      eve.target instanceof HTMLDivElement &&
      eve.target.classList.contains(rowClickerClass)
    ) {
      const rowIdentifier = hoveredRowClass.current.split('-')[1];
      if (onRowClickRef.current && rowIdentifier) {
        onRowClickRef.current(rowIdentifier);
      }
    }
  }, []);

  useEffect(() => {
    return () => {
      bodyRef.current?.removeEventListener('mouseover', handleHover);
      bodyRef.current?.removeEventListener('mouseleave', handleLeave);
      bodyRef.current?.removeEventListener('mouseup', handleClick);
    };
  }, [handleClick, handleHover, handleLeave]);

  const bindRef = (ref: HTMLDivElement) => {
    if (onRowClick) {
      bodyRef.current = ref;
      if (ref) {
        ref.addEventListener('mouseover', handleHover);
        ref.addEventListener('mouseleave', handleLeave);
        ref.addEventListener('mouseup', handleClick);
      }
    }
  };

  return (
    <TableBody
      ref={bindRef}
      className={className}
      columnSettings={columnSettings}
      style={{
        gridTemplateColumns: columnSettings
          .map((columnSetting) => columnSetting.width || '1fr')
          .join(' '),
      }}
      asClickable={!!onRowClick}
    >
      {columnSettings.map((columnSetting, index) => (
        <div
          key={columnSetting.columnIdentifier ?? index}
          className={`${tableHeadClass} ${makeTableCellPositionClass(
            index,
            -1,
            columnSettings.length,
            rows.length,
            columnSetting.columnIdentifier ?? String(index),
            '-1'
          )}`}
          onClick={columnSetting.onHeaderClick}
        >
          {columnSetting.header}
        </div>
      ))}

      {rows.map((row, rowIndex) =>
        columnSettings.map((columnSetting, columnIndex) => {
          const rowIdentifier = rowIdentifierCreator
            ? rowIdentifierCreator(row)
            : String(rowIndex);
          const columnIdentifier =
            columnSetting.columnIdentifier ?? String(columnIndex);

          return (
            <div
              key={rowIdentifier + columnIdentifier}
              className={`${tableCellClass} ${makeTableCellPositionClass(
                columnIndex,
                rowIndex,
                columnSettings.length,
                rows.length,
                columnIdentifier,
                rowIdentifier
              )}`}
            >
              {columnSetting.cellRenderer
                ? columnSetting.cellRenderer({
                    rowIndex,
                    row,
                    rowIdentifier,
                  })
                : columnSetting.attribute
                ? columnSetting.formatter
                  ? columnSetting.formatter(row[columnSetting.attribute])
                  : row[columnSetting.attribute]
                : null}
            </div>
          );
        })
      )}

      <div ref={rowClickRef} className={rowClickerClass} />
    </TableBody>
  );
};

/**
 * @deprecated use ReactTable component instead.
 */
export default React.memo(Table) as typeof Table;
