import { Table } from '@tanstack/react-table';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { Colors, Sizes } from 'utils/style';
import { Input, Select } from 'components/form';
import React from 'react';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px ${Sizes.Gutter}px;
  gap: 10px;
`;

const RowCountSetting = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
`;

const PaginationButtons = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const PageNrInput = styled(Input)`
  width: 3em;
  padding: 2px 5px;
  padding-right: 0;
  text-align: right;
`;

const IconButton = styled.button`
  padding: 5px;
  margin: 0;

  appearance: none;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1.5em;
  color: ${Colors.Red};
`;

interface Props<T> {
  table: Table<T>;
  quickFilterReset?: () => void;
}

const Pagination = <T,>({ table, quickFilterReset }: Props<T>) => {
  return (
    <Wrapper>
      <PaginationButtons>
        <IconButton
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        </IconButton>
        <IconButton
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </IconButton>

        <span>
          <PageNrInput
            type="number"
            value={table.getState().pagination.pageIndex + 1}
            min={1}
            max={table.getPageCount()}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
          />
          <b> av {table.getPageCount()}</b>
        </span>

        <IconButton
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </IconButton>
        <IconButton
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          <FontAwesomeIcon icon={faAngleDoubleRight} />
        </IconButton>
      </PaginationButtons>

      <RowCountSetting>
        <div>Resultat per sida: </div>
        <Select
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
        >
          {[5, 10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </Select>
      </RowCountSetting>
    </Wrapper>
  );
};

export default Pagination;
