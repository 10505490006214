// separate file from MapPage to reduce component size and complexity
import React, { FC, useCallback, useEffect } from 'react';
import { PageProps } from 'components/UserFlow/FlowConfig';
import { FormValues } from './orderFlowConfig';
import Map, { MapPositionOption } from './MapPage';
import { RequestStatus } from 'api/hooks/useApiCall';
import { mapPositionFromRepairshopInfo } from './util';
import { useUserFlowDataContext } from './useOrderUserFlowData';

interface Props extends PageProps<FormValues> {}

const PrefilledMapPage: FC<Props> = ({
  flowContext: { formValues, updateFormValues, currentPageIndex },
}) => {
  const { choosableRepairshopsResponse } = useUserFlowDataContext();

  useEffect(
    () => () => {
      updateFormValues((values) => ({
        ...values,
        autoFitMapToMarkers: true,
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const positionOptions: MapPositionOption[] =
    choosableRepairshopsResponse.status === RequestStatus.Fetched &&
    choosableRepairshopsResponse.response
      ? choosableRepairshopsResponse.response
          // .filter((r) => !!r.repairshopInfo?.address)
          .map((r) => ({
            label: r.customerName ?? '',
            position: mapPositionFromRepairshopInfo(r.repairshopInfo!),
            repairshopId: r.repairshopInfo?.id,
          }))
      : [];

  return (
    <Map
      pickingVehiclePosition={currentPageIndex === 0}
      autoFitToMarkers={formValues.autoFitMapToMarkers}
      onBoundsSetToMarkers={useCallback(() => {
        updateFormValues((formVals) => ({
          ...formVals,
          autoFitMapToMarkers: false,
        }));
      }, [updateFormValues])}
      vehiclePosition={formValues.vehicleOrigin}
      vehicleDestination={formValues.vehicleDestination}
      setVehicleDestination={useCallback(
        (dest, fromOption) =>
          updateFormValues((formVals) => ({
            ...formVals,
            vehicleDestination: dest,
            autoFitMapToMarkers: fromOption,
          })),
        [updateFormValues]
      )}
      setVehiclePosition={useCallback(
        (pos, fromOption) =>
          updateFormValues((formVals) => ({
            ...formVals,
            vehicleOrigin: pos,
            autoFitMapToMarkers: fromOption,
            inventoryId: formVals.inventoryId,
          })),
        [updateFormValues]
      )}
      vehicleDestinationOptions={positionOptions}
      vehiclePositionOptions={positionOptions}
    />
  );
};

export default PrefilledMapPage;
