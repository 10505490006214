import { faComment, faClipboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DepartmentType,
  InventoryDeliveryType,
  InventoryModel,
  InventoryStatus,
} from 'api';
import { ReactTable } from 'components/ReactTable';
import useAppTexts from 'hooks/useAppTexts';
import React, { FC, useMemo } from 'react';
import { generatePath, useHistory } from 'react-router';
import styled from 'styled-components';
import {
  isToday,
  toLocalDateTimeString,
  toLocalHHMMString,
  toShortLocalDateString,
} from 'utils/dateTime';
import Routes, { RouteParams } from 'utils/routes';
import { Colors } from 'utils/style';

export interface InventoryTableItem {
  id: number;
  registrationNumber: string;
  departmentType?: DepartmentType;
  status: InventoryStatus;
  stagingLotName: string;
  numberOfComments: number;
  deliveryToStagingLot?: Date;
  toStagingLotIsPlanned: boolean; // If true, deliveryToStagingLot is planned, otherwise it is actual
  deliveryToRepairShop?: Date;
  toRepairShopIsPlanned: boolean; // If true, deliveryToRepairShop is planned, otherwise it is actual
  toStagingLotPickUpType?: InventoryDeliveryType;
  fromStagingLotPickUpType?: InventoryDeliveryType;
  actualPickupToRepairShop?: Date;
  daysAsInventory?: number;
  inspection?: Date;
}

export function InventoryModelToInventoryTableItem(
  model: InventoryModel
): InventoryTableItem {
  return {
    id: model.id,
    registrationNumber: model.registrationNumber ?? '',
    departmentType: model.departmentType,
    status: model.status,
    stagingLotName: model.stagingLotName ?? '',
    numberOfComments: model.numberOfComments,
    deliveryToStagingLot:
      model.transferToStagingLot?.actualDelivery ??
      model.transferToStagingLot?.plannedDelivery,
    toStagingLotIsPlanned: !model.transferToStagingLot?.actualDelivery,
    deliveryToRepairShop:
      model.transferToRepairShop?.actualDelivery ??
      model.transferToRepairShop?.plannedDelivery,
    toRepairShopIsPlanned: !model.transferToRepairShop?.actualDelivery,
    toStagingLotPickUpType: model.transferToStagingLot?.pickUpType,
    fromStagingLotPickUpType: model.transferToRepairShop?.pickUpType,
    actualPickupToRepairShop: model.transferToRepairShop?.actualPickup,
    daysAsInventory: calculateDaysAsInventory(model),
    inspection: model.inspection,
  };
}

const calculateDaysAsInventory = (inventory: InventoryModel) => {
  let actualDeliveryToStagingLot =
    inventory.transferToStagingLot?.actualDelivery;
  let actualDeliveryToRepairShop =
    inventory.transferToRepairShop?.actualDelivery;
  let actualPickupToRepairShop = inventory.transferToRepairShop?.actualPickup;

  if (!actualDeliveryToStagingLot) {
    return;
  }
  let vehicleLeavingDate =
    actualDeliveryToRepairShop ?? actualPickupToRepairShop;
  if (!vehicleLeavingDate) {
    vehicleLeavingDate = new Date();
  }
  const arriveDate = new Date(actualDeliveryToStagingLot).setHours(0, 0, 0, 0);
  const leaveDate = new Date(vehicleLeavingDate).setHours(0, 0, 0, 0);
  const timeDiff = leaveDate - arriveDate;
  const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

  return daysDiff;
};

const CellWithIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  svg {
    color: ${Colors.DarkGray};
  }
`;

const DateAndChipCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const DeliveryDate = styled.div<{ isPlanned: boolean }>`
  font-style: ${({ isPlanned }) => (isPlanned ? 'italic' : 'normal')};
  color: ${({ isPlanned }) => (isPlanned ? Colors.DarkGray : Colors.Black)};
  .nowrap-date {
    white-space: nowrap;
  }
`;

const PickUpTypeChip = styled.span`
  padding: 2px 10px 1px;
  border-radius: 50px/50px;
  border: 1px solid ${Colors.Gray};
`;

const RegistrationNumberText = styled.span`
  margin-right: 5px;
  font-family: monospace;
`;

const InspectionWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${Colors.Black};
  color: ${Colors.White};
  text-align: center;
  border-radius: 7px;
  padding: 0px 4px;
  gap: 5px;
  font-size: 12px;
`;

/** Rank inventory differently in table depending on available attributes */
function getInventorySortingRank(inventory: InventoryTableItem) {
  if (inventory.deliveryToRepairShop) {
    return 1;
  }
  if (!inventory.deliveryToRepairShop && !inventory.deliveryToStagingLot) {
    return 2;
  }
  if (inventory.deliveryToStagingLot) {
    return 3;
  }
  return 0;
}

interface Props {
  inventories: InventoryTableItem[];
}
const InventoryTable: FC<Props> = ({ inventories }) => {
  const history = useHistory();

  const { departmentTypes, inventoryStatuses, inventoryPickupTypes } =
    useAppTexts();

  const sortedInventories = useMemo(
    () =>
      inventories.sort((a, b) => {
        const aRank = getInventorySortingRank(a);
        const bRank = getInventorySortingRank(b);

        // if a and b are in different ranks, sort by rank
        if (aRank !== bRank) {
          return aRank - bRank;
        }

        // If both have deliveryToRepairShop, sort by date
        if (a.deliveryToRepairShop && b.deliveryToRepairShop) {
          return (
            new Date(a.deliveryToRepairShop).getTime() -
            new Date(b.deliveryToRepairShop).getTime()
          );
        }

        // If both have deliveryToStagingLot, sort by reversed date
        if (a.deliveryToStagingLot && b.deliveryToStagingLot) {
          return (
            new Date(b.deliveryToStagingLot).getTime() -
            new Date(a.deliveryToStagingLot).getTime()
          );
        }

        return 0;
      }),
    [inventories]
  );

  const getDeliveryCellContent = (
    isPlanned: boolean,
    deliveryDate?: Date,
    pickupType?: InventoryDeliveryType
  ) => {
    if (!deliveryDate) return <span>-</span>;

    return (
      <DateAndChipCell>
        <DeliveryDate isPlanned={isPlanned}>
          <span className={'nowrap-date'}>
            {toLocalDateTimeString(deliveryDate as Date)}
          </span>
        </DeliveryDate>
        {pickupType !== undefined && (
          <PickUpTypeChip title={'Utförare'}>
            {pickupType === InventoryDeliveryType.Ak
              ? 'AK'
              : inventoryPickupTypes[pickupType as number]}
          </PickUpTypeChip>
        )}
      </DateAndChipCell>
    );
  };

  return (
    <ReactTable
      onRowClick={(row) => {
        history.push(
          generatePath(Routes.InventoryItem, {
            [RouteParams.inventoryItemId]: row.id,
          })
        );
      }}
      columns={[
        {
          header: 'Status',
          sortingFn: (a, b) => a.original.status - b.original.status,
          accessorFn: (row) => inventoryStatuses[row.status as number] ?? '',
          size: 200,
        },
        {
          header: 'Reg. nummer',
          accessorKey: 'registrationNumber',
          cell: ({ row: { original } }) =>
            original.inspection ? (
              <CellWithIcon>
                <RegistrationNumberText>
                  {original.registrationNumber}
                </RegistrationNumberText>
                <InspectionWrapper>
                  <FontAwesomeIcon icon={faClipboard} />
                  {isToday(original.inspection)
                    ? toLocalHHMMString(original.inspection)
                    : toShortLocalDateString(original.inspection)}
                </InspectionWrapper>
              </CellWithIcon>
            ) : (
              <RegistrationNumberText>
                {original.registrationNumber}
              </RegistrationNumberText>
            ),
          size: 180,
        },
        {
          header: 'Uppställningsplats',
          accessorKey: 'stagingLotName',
          size: 250,
        },
        {
          header: 'Verkstadstyp',
          accessorFn: (row) =>
            departmentTypes[row.departmentType as number] ?? '',
        },
        {
          header: 'Kommentarer',
          accessorKey: 'numberOfComments',
          cell: ({ row: { original } }) =>
            original.numberOfComments > 0 ? (
              <CellWithIcon>
                <FontAwesomeIcon icon={faComment} />
                {original.numberOfComments}
              </CellWithIcon>
            ) : null,
          size: 130,
        },
        {
          header: 'Till uppställning',
          accessorKey: 'deliveryToStagingLot',
          cell: ({ row: { original } }) =>
            getDeliveryCellContent(
              original.toStagingLotIsPlanned,
              original.deliveryToStagingLot,
              original.toStagingLotPickUpType
            ),
          size: 220,
        },
        {
          header: 'Till verkstad',
          accessorKey: 'deliveryToRepairShop',
          cell: ({ row: { original } }) =>
            getDeliveryCellContent(
              original.toRepairShopIsPlanned,
              original.deliveryToRepairShop,
              original.fromStagingLotPickUpType
            ),
          size: 220,
        },
        {
          header: 'Dagar uppställd',
          accessorKey: 'daysAsInventory',
          size: 160,
        },
      ]}
      data={sortedInventories}
    />
  );
};

export default InventoryTable;
