import useOutsideClick from 'hooks/useOutsideClick';
import React, { FC, useCallback, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Colors, MediaQuery, Sizes } from 'utils/style';
import { MapPosition, MapPositionOption } from '.';
import { Input, inputStyle } from 'components/form';

const Button = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin: 0;
  padding: 10px;

  border: 0;
  font-size: 20px;
  color: ${Colors.White};
  background-color: ${Colors.Red};
`;

const squeezeIn = keyframes`
  0% {
    max-height: 0;
  }
  100% {
    max-height: 250px;
  }
`;

const Dropdown = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 0;
  left: 0;
  top: calc(100% + 5px);
  z-index: 1;
  width: 100vw;
  overflow: visible;

  background: ${Colors.White};
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.4);
  color: ${Colors.Black};
  font-size: 1rem;

  animation: ${squeezeIn} 0.2s;

  ${MediaQuery.tablet} {
    width: auto;
  }

  &:before {
    content: '';
    position: absolute;
    top: -10px;
    right: 15px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid ${Colors.White};
    z-index: 2;
  }
`;

const DropdownSearchInputs = styled.div`
  ${inputStyle}
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  border-left: none;
  border-right: none;
  border-top: none;

  i {
    position: absolute;
    left: 15px;
  }
`;

const DropdownSearchInput = styled(Input)`
  flex: 1;
  padding-left: 40px;
  border: 0;
`;

const DropdownScroll = styled.div`
  max-height: 200px;
  overflow-y: auto;
`;

const Option = styled.button`
  width: 100%;
  padding: ${Sizes.Gutter / 4}px ${Sizes.Gutter / 2}px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;

  cursor: pointer;
  font: inherit;
  color: ${Colors.Black};
  text-overflow: ellipsis;
  border: 0;

  background: ${Colors.White};
  &:nth-child(even) {
    background: ${Colors.DarkWhite};
  }

  &:active,
  &:focus {
    background: ${Colors.Gray};
  }
`;

interface Props {
  options: MapPositionOption[];
  onPositionPicked(position: MapPosition): void;
}

/** Requires its parent container to be a positioned element for the dropdown to work properly.*/
const MapPositionOptionsPicker: FC<Props> = ({ options, onPositionPicked }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const wrapRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [searchString, setSearchString] = useState('');
  const searchStringLower = searchString.toLowerCase();

  useOutsideClick([buttonRef, wrapRef], () => {
    setOpen(false);
  });

  const handleSelect = useCallback(
    (option: MapPositionOption) => {
      const position = option.position;

      setOpen(false);
      setSearchString('');

      if (position) {
        onPositionPicked(position);
      }
    },
    [onPositionPicked]
  );

  return (
    <>
      <Button ref={buttonRef} onClick={() => setOpen((o) => !o)}>
        <i className="las la-warehouse" />
        <i
          className={open ? 'las la-angle-up' : 'las la-angle-down'}
          style={{ fontSize: 15 }}
        />
      </Button>

      {open && (
        <Dropdown ref={wrapRef}>
          <DropdownSearchInputs>
            <i className="las la-search" />
            <DropdownSearchInput
              type="text"
              placeholder="Filtrera"
              autoFocus
              value={searchString}
              onChange={(eve) => setSearchString(eve.target.value)}
            />
          </DropdownSearchInputs>

          <DropdownScroll>
            {options
              .filter((opt) =>
                opt.label.toLowerCase().includes(searchStringLower)
              )
              .sort((a, b) => a.label.localeCompare(b.label))
              .map((opt, i) => (
                <Option
                  key={i}
                  onClick={(eve) => {
                    eve.stopPropagation();
                    handleSelect(opt);
                  }}
                >
                  <b>{opt.label}</b>
                  <br />
                  <i>{opt.position.address}</i>
                </Option>
              ))}
          </DropdownScroll>
        </Dropdown>
      )}
    </>
  );
};

export default MapPositionOptionsPicker;
