import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import HomePage from './containers/HomePage';
import NotificationPage from 'containers/NotificationPage';
import Cases from './containers/Cases';
import OrderPage from 'containers/OrderPage';

import './custom.css';
import Routes from './utils/routes';
import { ModalProvider } from 'components/Modal/useModalStack';
import { withScriptjs } from 'react-google-maps';
import AdminPage from 'containers/AdminPage';
import CustomerAdminPage from 'containers/AdminPage/CustomerAdminPage';
import { UserInfoProvider } from 'hooks/useUserInfo';
import VisibleForRole from 'components/VisibleForRole';
import { Role } from 'api';
import CustomerIdCookieWatcher from 'utils/CustomerIdCookieWatcher';
import UserAdminPage from 'containers/AdminPage/UserAdminPage';
import StationAdminPage from 'containers/AdminPage/StationAdminPage';
import MyRepairshopPage from 'containers/MyRepairshopPage';
import RepairshopUsersPage from 'containers/RepairshopUsersPage';
import Inventory from 'containers/InventoryPage';
import { AppTextsProvider } from 'hooks/useAppTexts';
import { QueryParamUpdatesContextProvider } from 'hooks/useQueryParam';

class AppBase extends Component {
  static displayName = AppBase.name;

  render() {
    return (
      <QueryParamUpdatesContextProvider>
        <UserInfoProvider>
          <AppTextsProvider>
            <ModalProvider>
              <CustomerIdCookieWatcher />

              <Layout>
                {/* <Route
            path={ApplicationPaths.ApiAuthorizationPrefix}
            component={ApiAuthorizationRoutes}
          /> */}
                <Route exact path="/" component={HomePage} />
                <Route
                  path={Routes.Notification}
                  component={NotificationPage}
                />
                <Route
                  path={Routes.MyRepairshop}
                  component={MyRepairshopPage}
                />
                <Route
                  path={Routes.RepairshopUsers}
                  component={RepairshopUsersPage}
                />
                <Route path={Routes.Cases} component={Cases} />
                {/* <Route path={Routes.Towers} component={Towers} /> */}
                <Route path={Routes.Order} component={OrderPage} />

                <Route path={Routes.Inventory} component={Inventory} />

                <VisibleForRole roles={[Role.Admin]}>
                  <Route
                    exact
                    path={Routes.AdminCustomersTab}
                    component={AdminPage}
                  />
                  <Route
                    exact
                    path={Routes.AdminUsersTab}
                    component={AdminPage}
                  />
                  <Route
                    exact
                    path={Routes.AdminStationsTab}
                    component={AdminPage}
                  />

                  <Route
                    exact
                    path={Routes.CustomerAdmin}
                    component={CustomerAdminPage}
                  />
                  <Route
                    exact
                    path={Routes.UserAdmin}
                    component={UserAdminPage}
                  />
                  <Route
                    exact
                    path={Routes.StationAdmin}
                    component={StationAdminPage}
                  />
                </VisibleForRole>
              </Layout>
            </ModalProvider>
          </AppTextsProvider>
        </UserInfoProvider>
      </QueryParamUpdatesContextProvider>
    );
  }
}

const AppWithGoogleMaps = withScriptjs(AppBase);

const mapKey = `https://maps.googleapis.com/maps/api/js?key=${
  process.env.NODE_ENV === 'development'
    ? 'AIzaSyDmdpYDWEjFm8W1looQLnDOjwgJ8kieUnA'
    : 'AIzaSyDYHHgip7FgRZ8TmK1Zr_E-rDVXjeuW9lw'
}&v=3.exp&libraries=geometry,drawing,places`;

const App = (props: any) => (
  <AppWithGoogleMaps
    {...props}
    googleMapURL={mapKey}
    loadingElement={<div />}
  />
);

export default App;
