import React, { useState, useCallback, useEffect, useRef } from 'react';

import IconLabelInput from 'components/form/IconLabelInput';
import MarkerMap, { Coordinate, MarkerSetting } from 'containers/MarkerMap';
import createIcon, { carImage } from 'utils/mapIcon';
import { Colors, Sizes, MediaQuery } from 'utils/style';
import styled from 'styled-components';
import { getSwedenBounds, calcBounds } from 'containers/MarkerMap/util';
import { getAddressFromCoords } from '../util';
import useFetchErrorHandler from 'api/hooks/useFetchErrorHandler';
import StandaloneSearchBox from 'react-google-maps/lib/components/places/StandaloneSearchBox';
import MapPositionOptionsPicker from './MapPositionOptionsPicker';

export const MapWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const SearchInputsWrapper = styled.div`
  border-top: 1px solid ${Colors.Gray};

  ${MediaQuery.tablet} {
    position: absolute;
    top: ${Sizes.Gutter / 2}px;
    left: ${Sizes.Gutter}px;
    right: ${Sizes.Gutter / 2}px;
    width: ${Sizes.Map - Sizes.Gutter}px;
    z-index: 2;
    border: none;
  }
`;

const SearchInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  border-bottom: 1px solid ${Colors.Gray};

  & > *:first-child {
    flex: 1;
    border: 0;
  }

  ${MediaQuery.tablet} {
    margin-bottom: ${Sizes.Gutter / 2}px;
    border: 1px solid ${Colors.Gray};
  }
`;

const vehiclePositionMarker: Readonly<MarkerSetting> = {
  name: 'Hämtas',
  iconUrl: createIcon({
    color: Colors.Black,
    sizeFactor: 0.5,
    imageUrl: carImage,
  }),
  position: undefined,
} as const;

const vehicleDestinationMarker: Readonly<MarkerSetting> = {
  name: 'Lämnas',
  iconUrl: createIcon({
    color: Colors.Red,
    sizeFactor: 0.5,
  }),
  position: undefined,
} as const;

export interface MapPosition {
  coordinate?: Coordinate;
  address: string;
  repairshopId?: number;
}

export interface MapPositionOption {
  position: MapPosition;
  label: string;
}

interface Props {
  pickingVehiclePosition: boolean;
  autoFitToMarkers?: boolean;
  onBoundsSetToMarkers?(): void;

  vehiclePosition: MapPosition;
  vehicleDestination: MapPosition;
  setVehicleDestination(dest: MapPosition, fromOption: boolean): void;
  setVehiclePosition(dest: MapPosition, fromOption: boolean): void;

  vehiclePositionOptions: MapPositionOption[];
  vehicleDestinationOptions: MapPositionOption[];
}

const Map = (props: Props) => {
  const {
    pickingVehiclePosition,
    autoFitToMarkers,
    onBoundsSetToMarkers,
    vehiclePosition,
    vehicleDestination,
    setVehicleDestination,
    setVehiclePosition,
    vehiclePositionOptions,
    vehicleDestinationOptions,
  } = props;

  const [handleFetchError] = useFetchErrorHandler();
  const [isFetchingPosition, setIsFetchingPosition] = useState(false);
  const [bounds, setBounds] = useState<google.maps.LatLngBounds | undefined>(
    undefined
  );
  const [markers, setMarkers] = useState<MarkerSetting[]>([]);
  const positionSearchBoxRef = useRef<StandaloneSearchBox>(null);
  const destinationSearchBoxRef = useRef<StandaloneSearchBox>(null);

  useEffect(() => {
    setBounds(getSwedenBounds());
  }, []);

  useEffect(() => {
    const newMarkerSettings: MarkerSetting[] = [
      { ...vehiclePositionMarker, position: vehiclePosition.coordinate },
      { ...vehicleDestinationMarker, position: vehicleDestination.coordinate },
    ];
    setMarkers(newMarkerSettings);

    if (
      autoFitToMarkers &&
      (vehiclePosition.coordinate || vehicleDestination.coordinate)
    ) {
      onBoundsSetToMarkers && onBoundsSetToMarkers();
      if (vehiclePosition.coordinate && vehicleDestination.coordinate) {
        fitCoordinates(
          vehicleDestination.coordinate,
          vehiclePosition.coordinate
        );
      } else {
        fitCoordinates(
          (vehicleDestination.coordinate || vehiclePosition.coordinate)!,
          undefined
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    vehicleDestination.coordinate,
    vehiclePosition.coordinate,
    autoFitToMarkers,
  ]);

  const handleMapClick = useCallback(
    async (eve: google.maps.MouseEvent | google.maps.IconMouseEvent) => {
      if (!eve.latLng) return;

      onBoundsSetToMarkers && onBoundsSetToMarkers();

      const setAction = pickingVehiclePosition
        ? setVehiclePosition
        : setVehicleDestination;

      const position: Coordinate = {
        lat: eve.latLng.lat(),
        lng: eve.latLng.lng(),
      };

      setAction(
        {
          address: '',
          coordinate: position,
        },
        false
      );
      try {
        setAction(
          await getAddressFromCoords(position, setIsFetchingPosition),
          false
        );
      } catch (err) {
        handleFetchError(err);
      }
    },
    [
      pickingVehiclePosition,
      handleFetchError,
      onBoundsSetToMarkers,
      setVehicleDestination,
      setVehiclePosition,
    ]
  );

  const handlePlacesChanged = (
    searchBox: StandaloneSearchBox | null,
    setPositionCallback:
      | Props['setVehiclePosition']
      | Props['setVehicleDestination']
  ) => {
    if (!searchBox) {
      return;
    }

    const places = searchBox.getPlaces();
    const result = places[0];

    if (places.length === 1 && result.geometry && result.geometry.location) {
      const location = result.geometry.location;
      const longitude = location.lng();
      const latitude = location.lat();

      setPositionCallback(
        {
          address: result.formatted_address ?? '',
          coordinate: {
            lat: latitude,
            lng: longitude,
          },
        },
        false
      );

      if (setPositionCallback === setVehiclePosition) {
        fitCoordinates({
          lat: latitude,
          lng: longitude,
        });
      } else if (setPositionCallback === setVehicleDestination) {
        fitCoordinates(
          {
            lat: latitude,
            lng: longitude,
          },
          vehiclePosition.coordinate
        );
      }
    } else {
      handleFetchError(Error('Något gick fel, försök igen'));
    }
  };

  const fitCoordinates = (coord1: Coordinate, coord2?: Coordinate) => {
    const margin = 0.003;

    if (coord2) {
      setBounds(calcBounds([coord1, coord2]));
    } else {
      const n = new window.google.maps.LatLngBounds(
        { lat: coord1.lat - margin, lng: coord1.lng - margin },
        { lat: coord1.lat + margin, lng: coord1.lng + margin }
      );

      setBounds(n);
    }
  };

  return (
    <MapWrapper>
      <SearchInputsWrapper>
        <SearchInputWrapper>
          <StandaloneSearchBox
            ref={positionSearchBoxRef}
            onPlacesChanged={() =>
              handlePlacesChanged(
                positionSearchBoxRef.current,
                setVehiclePosition
              )
            }
          >
            <IconLabelInput
              label="Hämtas"
              value={vehiclePosition.address}
              locked={!pickingVehiclePosition}
              onChange={(value: string) =>
                setVehiclePosition(
                  { ...vehiclePosition, address: value },
                  false
                )
              }
              iconName={
                pickingVehiclePosition
                  ? isFetchingPosition
                    ? 'las la-spinner la-pulse'
                    : 'las la-search'
                  : undefined
              }
            />
          </StandaloneSearchBox>

          {pickingVehiclePosition && vehiclePositionOptions.length > 1 && (
            <MapPositionOptionsPicker
              options={vehiclePositionOptions}
              onPositionPicked={(vp) => setVehiclePosition(vp, true)}
            />
          )}
        </SearchInputWrapper>

        {(!pickingVehiclePosition || vehicleDestination.address) && (
          <SearchInputWrapper>
            <StandaloneSearchBox
              ref={destinationSearchBoxRef}
              onPlacesChanged={() =>
                handlePlacesChanged(
                  destinationSearchBoxRef.current,
                  setVehicleDestination
                )
              }
            >
              <IconLabelInput
                label="Lämnas"
                value={vehicleDestination.address}
                locked={pickingVehiclePosition}
                onChange={(value: string) =>
                  setVehicleDestination(
                    {
                      ...vehicleDestination,
                      address: value,
                    },
                    false
                  )
                }
                iconName={
                  pickingVehiclePosition
                    ? undefined
                    : isFetchingPosition
                    ? 'las la-spinner la-pulse'
                    : 'las la-search'
                }
              />
            </StandaloneSearchBox>

            {!pickingVehiclePosition &&
              vehicleDestinationOptions.length > 1 && (
                <MapPositionOptionsPicker
                  options={vehicleDestinationOptions}
                  onPositionPicked={(vp) => setVehicleDestination(vp, true)}
                />
              )}
          </SearchInputWrapper>
        )}
      </SearchInputsWrapper>

      <MarkerMap
        bounds={bounds}
        markers={markers}
        handleMapClick={handleMapClick}
      />
    </MapWrapper>
  );
};

export default React.memo(Map);
